$(document).ready(function() {
	$('[data-toggle="tooltip"]').tooltip()
});


function openSite(){
    $("body").css("background-color", "transparent");
    $("header").css("visibility", "visible");
    $("main").css("visibility", "visible");
    $("footer").css("visibility", "visible");
    $("#welcome").fadeOut();
    setTimeout(function(){
        $("body").removeClass("welcome");
    }, 500)
}

// function loadPhotos(){
//   $("#photos-loader").fadeIn('fast');
//   $.getJSON(
//     "photos/page/" + galleryPage,
//     function(data){
//       photos = data.data;
//       $("#photos-loader").fadeOut('fast');
//       $.each(photos, function(i, photo){
//         photoItem = $('<div style="display: none" class="col-xs-12 col-sm-6 col-md-4"><a href="'+ baseUrl + photo.path +'" class="thumbnail fancybox" rel="lightbox" title="'+ photo.name +'" style="background: url('+ baseUrl + photo.path +')"></a></div>');

//         $(".gallery").append(photoItem);
//         photoItem.slideDown();
//       });
//       if(photos.length == 0){
//         $("#photos-button").fadeOut();
//       }else{
//         galleryPage++;
//       }
//     }
//   );
// }